import React, { useEffect, useState } from 'react'
import { Action, Box, Flex, Subheader, Toggle, TransitionCollapse } from '@revolut/ui-kit'

import ChatMessagesList from '@components/Chat/ChatMessagesList'
import ChatSidebar from '@components/Chat/ChatSidebar'
import ChatTagsManager from '@components/Chat/ChatTagsManager'
import ChatTextEditor, {
  ChatTextEditorVariant,
} from '@components/Chat/ChatTextEditor/ChatTextEditor'
import { ChatProps } from '@components/Chat/common'
import { CommonCommentsSectionProps } from '@src/features/Comments/CommentsSection'
import { DocumentPreview } from '@src/features/DocumentSidebar/DocumentPreview'
import { FileInterface } from '@src/interfaces/files'
import SideBar from '../SideBar/SideBar'

const COMMENTS_OFFSET = 3

interface Props extends ChatProps, CommonCommentsSectionProps {
  isCollapsableList?: boolean
}

const Chat = (props: Props) => {
  const {
    data,
    refetch,
    loadNextPage,
    onAddMessage,
    onSideOpen,
    onSideClose,
    setSidebarOpen,
    title = 'Comments',
    maxShown = 3,
    canAddComments = true,
    withFileUploader,
    withInfiniteLoading,
    isCollapsableList,
  } = props
  const [isSideOpen, setSideOpen] = useState<boolean>(false)
  const [previewAttachment, setPreviewAttachment] = useState<FileInterface>()

  const onPreviewAttachment = (file: FileInterface) => {
    setPreviewAttachment(undefined)
    setPreviewAttachment(file)
  }

  useEffect(() => {
    if (setSidebarOpen) {
      setSidebarOpen.current = setSideOpen
    }
  }, [])

  const showSide = Boolean(!props.withInfiniteLoading && data?.length)
  const showSubheader = Boolean(title || showSide)

  return (
    <Box>
      <ChatTagsManager>
        <Box pb="s-16">
          {showSubheader && !isCollapsableList && (
            <Subheader variant="nested">
              <Subheader.Title>{title}</Subheader.Title>
              {!props.withInfiniteLoading && data?.length ? (
                <Subheader.Side>
                  <Action
                    onClick={() => {
                      onSideOpen?.()
                      setSideOpen(true)
                    }}
                  >
                    See all ({data.length})
                  </Action>
                </Subheader.Side>
              ) : null}
            </Subheader>
          )}
          {canAddComments ? (
            <ChatTextEditor
              withFileUploader={withFileUploader}
              onPreviewAttachment={onPreviewAttachment}
              variant={ChatTextEditorVariant.Input}
              onSubmit={(text, createTask, attachedFiles) => {
                return onAddMessage(text, createTask, attachedFiles).then(refetch)
              }}
            />
          ) : null}
        </Box>

        {isCollapsableList && (
          <Toggle>
            {({ state, toggle }) => (
              <>
                <ChatMessagesList
                  {...props}
                  withInfiniteLoading={withInfiniteLoading}
                  loadNextPage={loadNextPage}
                  maxShown={COMMENTS_OFFSET}
                  showNoDataMessage
                  onPreviewAttachment={onPreviewAttachment}
                />
                <TransitionCollapse in={state}>
                  <ChatMessagesList
                    {...props}
                    offset={COMMENTS_OFFSET}
                    withInfiniteLoading={withInfiniteLoading}
                    loadNextPage={loadNextPage}
                    showNoDataMessage
                    onPreviewAttachment={onPreviewAttachment}
                  />
                </TransitionCollapse>
                {props?.data?.length && props.data.length > COMMENTS_OFFSET && (
                  <Flex justifyContent="center">
                    <Action onClick={() => toggle()}>
                      {state ? 'Collapse' : 'See all'}
                    </Action>
                  </Flex>
                )}
              </>
            )}
          </Toggle>
        )}

        {!isCollapsableList && (
          <ChatMessagesList
            {...props}
            withInfiniteLoading={withInfiniteLoading}
            loadNextPage={loadNextPage}
            maxShown={maxShown}
            showNoDataMessage
            onPreviewAttachment={onPreviewAttachment}
          />
        )}
        {!withInfiniteLoading && (
          <ChatSidebar
            {...props}
            isOpen={isSideOpen}
            onClose={() => {
              onSideClose?.()
              setSideOpen(false)
            }}
            withFileUploader={withFileUploader}
          />
        )}
      </ChatTagsManager>
      {previewAttachment && (
        <SideBar
          title="Preview attached file"
          isOpen={!!previewAttachment}
          onClose={() => setPreviewAttachment(undefined)}
          sideProps={{ resizable: true }}
        >
          <DocumentPreview
            id={previewAttachment.id}
            genericFileInfo={{ id: previewAttachment.id, name: previewAttachment.name }}
          />
        </SideBar>
      )}
    </Box>
  )
}

export default Chat
